<template>
  <div class="download-wrap" :style="{ 'background-image': 'url(' + bgUrl + ')', 'min-height': clientHeight }">
    <!-- 一体机主图 -->
    <div class="text">
      <p class="title">i餐饮App智慧餐饮</p>
      <p class="desc">一站式整体解决方案</p>
    </div>
    <div class="banner">
      <img :src="appData.imgDeviceHD && appData.imgDeviceHD.content" alt="" />
    </div>
    <div class="download-btn">
      <a class="btn" download="i餐饮HD" @click="download">立即下载</a>
    </div>
    <img class="top_img" :src="appData.imgOrderHD && appData.imgOrderHD.content" alt="" />
    <img class="bottom_img" :src="appData.imgRestaurantHD && appData.imgRestaurantHD.content" alt="" />
    <action-sheet v-model="show" :actions="actions" @select="onSelect" :round="false" :close-on-click-action="true" />
  </div>
</template>

<script>
import downloadMixin from '@/mixin/downloadMixin'
import { isWeixin, getPhoneBrand } from '@/utils/utils'
export default {
  name: 'DownloadHd',
  mixins: [downloadMixin],
  computed: {
    bgUrl() {
      return (this.appData.bgImgHD && this.appData.bgImgHD.content) || ''
    },
  },
  methods: {
    onSelect(item) {
      if (item.type === 0) {
        this.linkDown(this.info.path)
      } else {
        this.linkDown(this.brandList[this.brand] + this.apkHdId)
      }
      this.downloadCount()
    },

    download() {
      if (isWeixin()) {
        this.linkDown('https://sj.qq.com/appdetail/' + this.apkHdId)
        return
      }
      if (this.type === 0) {
        //安卓
        this.brand = getPhoneBrand()
        if (this.brand != 'other') {
          this.show = true
        } else {
          this.linkDown(this.info.path)
        }
      } else {
        let _href = (this.appData.downloadLinkHD && this.appData.downloadLinkHD.content) || null
        this.linkDown(_href)
      }
    },
    async downloadCount() {
      // 用户点击了下载按钮 应该调统计下载次数接口了
      const id = this.info.id
      const { success } = await this.$axios({
        url: `/admin/app/downloadStatistics?id=${id}`,
      })
      if (success) {
        console.log('下载统计成功')
      }
    },
    async getInfo() {
      const { success, result } = await this.$axios({
        url: `/admin/app/android/version/20/${this.envId}`,
      })
      if (success) {
        this.info = result
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@function vw($px) {
  @return $px / 10.24 + vw;
}
@function vh($px) {
  @return $px / 7.68 + vh;
}
@font-face {
  font-family: Alibaba-PuHuiTi-M;
  src: url('../fonts/Alibaba-PuHuiTi-Bold.ttf');
}

.download-wrap {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: vh(145);
  .banner {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    img {
      width: vw(852);
      height: vh(452);
    }
  }
  .text {
    position: relative;
    z-index: 2;
    margin-left: vw(67);
    p.title {
      font-size: vh(60);
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi, Arial, Helvetica, sans-serif;
      font-weight: normal;
      color: $red;
    }
    p.desc {
      font-size: vh(44);
      @include fontRegular;
      color: $red;
      letter-spacing: vh(12);
    }
  }
  .top_img {
    position: absolute;
    top: vh(218);
    right: vw(96);
    width: vw(84);
    z-index: 1;
  }
  .bottom_img {
    position: absolute;
    bottom: vh(78);
    left: vw(274);
    width: vw(84);
    z-index: 1;
  }
  .download-btn {
    position: relative;
    z-index: 3;
    margin-top: vh(72);
    margin-left: vw(64);
    a {
      display: inline-block;
      width: vw(274);
      height: vh(74);
      background-color: $red;
      border-radius: vw(10);
      font-size: vh(32);
      @include fontMedium;
      font-weight: 500;
      color: #fff;
      line-height: vh(74);
      text-align: center;
      letter-spacing: vh(10);
    }
  }
}

@mixin screen {
  .download-wrap {
    padding-top: vv(53);
    .banner {
      img {
        width: vv(312);
        height: vv(166);
      }
    }
    .text {
      margin-left: vv(25);
      p.title {
        font-size: vv(22);
      }
      p.desc {
        font-size: vv(16);
        letter-spacing: vv(4);
      }
    }
    .top_img {
      top: vv(80);
      right: vv(35);
      width: vv(31);
    }
    .bottom_img {
      bottom: vv(29);
      left: vv(100);
      width: vv(31);
    }
    .download-btn {
      margin-top: vv(26);
      margin-left: vv(23);
      a {
        width: vv(100);
        height: vv(27);
        border-radius: vv(4);
        font-size: vv(13);
        line-height: vv(30);
        letter-spacing: vv(4);
      }
    }
  }
}

@media screen and (orientation: portrait) {
  @include screen;
}

@media (max-width: 768px) {
  @include screen;
}
</style>
